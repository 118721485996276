import React from 'react';


class Files extends React.Component {



    render() {
        return(
        <div className="Files">
            <iframe src="https://randevu.it.petroviser.ru/fs/"  width={"100%"} height={"100%"} frameBorder="0"/>
        </div>
        )
    }
}


export default  Files;