import React from "react";
import {format} from "date-fns";
import Events from "./events";
import Nav from "./nav";
import {t} from "../../lang";
import Select from 'react-select'
import {IZMEventResponse, IZMEvent} from "../../models/zm_event";
import {IFilter} from "../../models/filter";
import EventVideo from "./eventvideo";

let ReactTable = require('react-table-v6').default;



interface IProps {
}

interface IState {
    timer: number,
    monitor: IZMEvent [],
    showPhoto: boolean,
    showVideo: boolean,
    selectedId: string
    starttime: string
    optAVG: IFilter[]
    avgValue: number
    avgCalcTime: number
    selectedAVG: IFilter
    selectedRow: number
}

const lag = 3 * 60 * 60 * 1000;
const timeout = 1000 * 30;

class Monitor extends React.Component<IProps, IState> {
    state: IState = {
        timer: 30,
        showPhoto: false,
        showVideo: false,
        selectedId: "",
        monitor: [],
        starttime: format(new Date((Date.now() - lag)), "yyyy-MM-dd HH:mm:ss"),
        optAVG:[{ value: 1, label:t("1 час")}, {value: 2, label:t("2 часа")}, {value: 3, label:t("3 часа")}],
        avgValue: 0,
        selectedAVG:{value: 3, label:t("3 часа")},
        avgCalcTime: Date.now(),
        selectedRow: -1

    };

    componentDidMount(): void {
        this.getEvents();

    }
getAVG(period: number ){
        console.log(period);

        let d= new Date((Date.now() - period* 60 * 60 * 1000));
        console.log(d);
    console.log(this.state.monitor.filter(ev=> new Date(ev.start_time).getTime()>= d.getTime())) ;

    this.setState({ avgValue: this.state.monitor.filter(ev=> new Date(ev.start_time).getTime()>= d.getTime()).length}) ;


        }

    getEvents = () => {

        fetch("https://randevu.it.petroviser.ru/vas/api/events", {
            headers: {'Content-Type': 'application/json'},
            method: 'POST',
            body: JSON.stringify({"datetime":this.state.starttime})
        })
            .then(res => res.json())
            .then(
                (result) => {
                    let dataset: IZMEventResponse = JSON.parse(JSON.stringify(result));
                    if (dataset && dataset.events && dataset.events.length > 0) {
                        dataset.events.sort((a, b) => new Date(a.start_time) > new Date(b.start_time) ? -1 : 1);
                        console.log(dataset.events[0].start_time);
                        this.setState({
                            monitor: [...dataset.events, ...this.state.monitor],
                            starttime: dataset.events[0].start_time

                        });
                        this.getAVG(Number(this.state.selectedAVG.value) )
                    }
                    else
                        if (Date.now()-this.state.avgCalcTime>=15*60*1000) {
                            this.setState({avgCalcTime: Date.now()});
                            this.getAVG(Number(this.state.selectedAVG.value));

                        }
                   console.log( Date.now()-(new Date(this.state.starttime)).getTime());

                },
                (error) => {
                    console.log(error.toString())
                }
            ).finally(() => setTimeout(this.getEvents, timeout));
    };
    handleChangeAVG = event => {
        this.setState({selectedAVG: event});
        this.getAVG(event.value);
    };
    hideModalPhoto = () => {
        this.setState({
            showPhoto: false
        });
    };
    showModalPhoto  = () => {
        this.setState({
            showPhoto: true
        });
    };
    onPhotoClick = e => {
        this.setState({selectedId: e});
        this.showModalPhoto();
    };
    onVideoClick = e => {
        this.setState({selectedId: e});
        this.showModalVideo();
    };
    hideModalVideo = () => {
        this.setState({
            showVideo: false
        });
    };
    showModalVideo  = () => {
        this.setState({
            showVideo: true
        });
    };
    render() {
        let columns = [
            {

                width:57 ,
                filterable: false,
                Cell: ({  original }) => (
                    <button className="TableActions" >
                        <i className="fas fa-images fa-2x" title={t("фото")} onClick={()=>this.onPhotoClick(original.id)}></i>
                    </button>
                )
            },
            {
                width:57 ,
                filterable: false,
                Cell: ({  original }) => (
                    <button className="TableActions" >
                        <i className="fas fa-film fa-2x" title={t("видео")} onClick={()=>this.onVideoClick(original.zm_event_id)}></i>
                    </button>
                )

            },

            {
                Header: t('Объект'),
                accessor: 'zm_object_name',
                minWidth: 100,
            },
            {
                Header: t('Монитор'),
                accessor: 'vas_monitor_name',
                minWidth: 100,
            },
            {
                Header: t('СИЗ'),
                id: 'no_hardhat',
                filterable: false,
                accessor: h => {
                    return h.no_hardhat ? t("Нет") : t("Да")
                },
                minWidth: 50,
                getProps: (state, rowInfo, column) => {
                    if (rowInfo && rowInfo.row)
                        return {
                            style: {
                                background: column.accessor(rowInfo.original) == t("Нет") ? "#996b66" :
                                    "#7a857b"
                            }
                        };
                    else return {};
                }
            },
            {
                Header: t('Количество человек'),
                accessor: 'number_person',
                minWidth: 50,
                filterable: false,
            },
            {
                Header: t('Возможные участники'),
                accessor: 'face_owners',
                minWidth: 50,
                filterable: false,
            },
            {
                Header: t('Id события'),
                accessor: 'zm_event_id',
                minWidth: 50,
                filterable: false,
            },
            {
                Header: t('Время'),
                accessor: 'start_time',
                minWidth: 100,
                filterable: false,
            }
        ];
        const data = this.state.monitor;
        return (
            <div className="MonitorContainer">
                <Nav/>

                <div className="Monitor">
                    <div className="title">
                        <h1>{t("Мониторинг в реальном времени")}</h1>
                        <div className="FilterRealTime">
                            <h2>{t("Количество нарушений за ")}</h2>
                            <Select
                                options={this.state.optAVG}
                                placeholder={t("Выбрать...")}
                                value={this.state.selectedAVG}
                                onChange={value => this.handleChangeAVG(value)}
                                noOptionsMessage={() => t("Нет опций")}/>
                                <h2>{" : "+this.state.avgValue}</h2>
                        </div>

                    </div>
                    {(this.state.showPhoto) && (
                        <Events eventId={this.state.selectedId} onClose={()=>this.hideModalPhoto()}/>)
                    }
                    {(this.state.showVideo) && (
                        <EventVideo eventId={this.state.selectedId} onClose={()=>this.hideModalVideo()}/>)
                    }
                    {(this.state.monitor.length > 0) ? (
                        <div>
                            <ReactTable
                                columns={columns}
                                data={data}
                                pageSize={this.state.monitor.length}
                                filterable
                                showPagination={false}
                                minRows={15}
                                style={{height: "70vh"}}
                                getTrGroupProps={(state, rowInfo) => {
                                    if (rowInfo !== undefined) {
                                        return {
                                            onClick: () => {
                                                this.setState({
                                                    selectedRow: rowInfo.original.id
                                                })
                                            },
                                            style: {
                                                cursor: 'pointer',
                                                background: rowInfo.original.id === this.state.selectedRow ? '#786f6e' : '',

                                            }
                                        }
                                    }}
                                }

                            >
                            </ReactTable>
                        </div>) : <div className="NoData">{t("Нет событий")}</div>
                    }


                </div>
            </div>
        );

    }

}

export default Monitor;