import React from "react";
import {IZMFrame, IZMFrameResponse, IZMFullScreenFrame} from "../../models/zm_frame";
import {t} from "../../lang";



interface IProps {
    eventId: string;
    onClose: any
}

interface IState {
    frames: IZMFrame[]
    selectedFrame: IZMFrame | any
    windows: Window []
}

class Events extends React.Component<IProps, IState> {
    state: IState = {
        frames: [],
        selectedFrame: {id: "", fs_frame: ""},
        windows:[]
    };

    clickedParent = () => {
        this.props.onClose()
    };
    clickedChild = () => {
    };

    componentDidMount(): void {
        fetch("https://randevu.it.petroviser.ru/vas/api/event/" + this.props.eventId)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    let dataset: IZMFrameResponse = JSON.parse(JSON.stringify(result));
                    if (dataset && dataset.frames) {

                        this.setState({frames: dataset.frames});
                    }
                },
                (error) => {
                    console.log(error.toString())
                }
            );
    }

    getFrame(frame_id: string, index): void {
        if (this.state.frames[index].fs_frame == undefined) {
            fetch("https://randevu.it.petroviser.ru/vas/api/frame/" + frame_id)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result);
                        let dataset: IZMFullScreenFrame = JSON.parse(JSON.stringify(result));
                        if (dataset && dataset.frame) {
                            this.state.frames[index].fs_frame = dataset.frame;
                            this.openFrameWindow((index+1).toString(), this.state.frames[index].id);
                        }
                    },
                    (error) => {
                        console.log(error.toString())
                    }
                );
        } else {
            this.openFrameWindow((index+1).toString(),this.state.frames[index].id);
        }
    }

    selectMiniFrame = (e, index) => {

        this.setState({selectedFrame: this.state.frames[index]});
        this.getFrame(e, index)
    }

    openFrameWindow(id: string, name: string) {


        let w =[...this.state.windows];
        w.filter(w=>w!=null&&w.name==name).forEach(w=>{console.log(w.name); console.log("w.name"); w.close()});

        //console.log(w);



        //const features = 'width:100%, height:100%';
         let externalWindow = window.open('', name,`height=${window.screen.height},width=${window.screen.width}`);
        console.log(externalWindow);
        if (externalWindow) {
            let t = "<title>" + id + "</title><img src= \"data:image/jpg;base64," + this.state.selectedFrame.fs_frame + "\"/>";
            externalWindow.document.write(t);


            this.setState({ windows: w.concat(externalWindow)})
        }

    }

    render() {
        return (<div className="Modal" onClick={this.clickedParent}>
            <div className="ModalActions">
                <button >
                    <i className="far fa-window-close fa-2x" title={t("Закрыть")} onClick={this.clickedParent}></i>
                </button>
            </div>
            <div className="Data" onClick={e => {
                e.stopPropagation();
                this.clickedChild();
            }}>


                {this.state.frames.map((f, index) => {
                    return (<i className="Row"><i className="Index">{index+1}</i><img src={"data:image/jpg;base64," + f.frame} onClick={() => this.selectMiniFrame(f.id, index)}/></i>)
                })}

            </div>
        </div>)
    }

}

export default Events;